<template>
  <b-container v-if="renderPage">
    <b-overlay
      :show="show_loading"
      rounded="lg"
      :opacity="1"
    >
      <template v-slot:overlay>
        <div class="d-flex align-items-center">
          <strong>{{ msg }}, Aguarde...</strong>
          <b-icon
            icon="stopwatch"
            font-scale="3"
            animation="cylon"
          />
          <b-spinner
            small
            type="grow"
            variant="primary"
          />
          <b-spinner
            type="grow"
            variant="primary"
          />
          <b-spinner
            small
            type="grow"
            variant="primary"
          />
        </div>
      </template>
      <b-col>
        <b-row
          class="justify-content-center"
        >
          <b-col
            xl="2"
            md="3"
            sm="3"
            lg="3"
          >
            <b-card title="Usuários">
              <div v-if="backofficeUsers.length">
                <b-list-group
                  v-for="item in backofficeUsers"

                  :key="item.id"
                  flush
                >
                  <b-list-group-item>{{ item.user.name }}</b-list-group-item>
                </b-list-group>
              </div>

              <p v-else>
                Perfil não tem usuários
              </p>
            </b-card>
          </b-col>

          <b-col

            xl="6"
            md="12"
            sm="12"
            lg="6"
          >

            <b-card title="Editar Perfil">
              <validation-observer ref="simpleRules">
                <b-form
                  novalidate
                  class="needs-validation"
                  @submit.prevent
                >
                  <b-row>
                    <b-col
                      sm="12"
                      lg="12"
                      md="12"
                      class="mb-1"
                    >
                      <label for="v-nome-perfil">Nome do Perfil:</label>
                      <validation-provider
                        #default="{ errors }"
                        name="profile"
                        rules="required"
                      >
                        <b-form-input
                          id="v-nome-perfil"
                          v-model="profileName"
                          placeholder="Informe o nome do Perfil"
                          :state="
                            errors.length > 0 ? false : profileName ? true : null
                          "
                        />
                        <b-form-valid-feedback>
                          Campo Preenchido!
                        </b-form-valid-feedback>
                        <b-form-invalid-feedback>
                          Por favor Preencha o campo
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col
                      sm="12"
                      lg="12"
                      md="12"
                    >

                      <b-form-group label="Escolha os Grupos">
                        <b-form-checkbox-group
                          v-model="groupsSelected"
                          inline
                          stacked
                          :options="groupsOptions"
                          style="column-count: 2"
                          switches
                        />
                      </b-form-group>
                    </b-col></b-row>
                  <b-row>
                    <b-col md="6">
                      <b-button
                        class="mt-1"
                        variant="primary"
                        block
                        @click="updateProfile"
                      >Editar</b-button>
                    </b-col>
                    <b-col md="6">
                      <b-button
                        class="mt-1"
                        variant="primary"
                        block
                        :to="{ name: 'usuarios' }"
                      >Voltar</b-button>
                    </b-col>
                  </b-row>
                </b-form>
              </validation-observer>
            </b-card>
          </b-col>
        </b-row>
      </b-col>

    </b-overlay>
  </b-container>
</template>
<script>
import {
    BButton,
    BFormGroup,
    BFormInput,
    BCol,
    BRow,
    BCard,
    BContainer,
    BForm,
    BFormValidFeedback,
    BFormInvalidFeedback,
    BSpinner,
    BOverlay,
    BIcon,
    BListGroup,
    BListGroupItem,
    BFormCheckboxGroup,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import '@validations';

import UserAtomService from '@/service/user-atom';

export default {
    components: {
        BButton,
        BFormGroup,
        BFormInput,
        BCol,
        BRow,
        BCard,
        BContainer,
        BForm,
        BFormValidFeedback,
        BFormInvalidFeedback,
        BSpinner,
        BOverlay,
        ValidationProvider,
        ValidationObserver,
        BIcon,
        BFormCheckboxGroup,
        BListGroup,
        BListGroupItem,
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            renderPage: true,
            profileName: null,
            show_loading: false,
            msg: ' ',
            backofficeUsers: [],
            options: {},
            groups: [],
            groupsSelected: [],
            id: this.$route.params.id,
        };
    },

    computed: {
        groupsOptions() {
            return this.groups.map(item => ({
                value: item.id,
                text: item.name,
            }));
        },
    },

    async mounted() {
        await this.getGroups();
        await this.getProfile();
    },

    methods: {
        async getGroups() {
            this.groups = (await UserAtomService.showGroups()).data.data;
        },

        async getProfile() {
            const { data } = await UserAtomService.showProfile({
                id: this.id,
            });

            this.profileName = data.data.name;
            this.groupsSelected = data.data.groups_ids.map(item => item.whatsapp_group_id);
            this.backofficeUsers = data.data.backoffice_users;
        },

        async updateProfile() {
            const response = await this.confirmAnAction('Deseja realmente criar esse perfil?');

            if (!response) return;
            this.msg = 'Editando Perfil';
            this.show_loading = true;

            const { profileName, groupsSelected } = this;

            const { status } = await UserAtomService.updateProfile({
                id: this.id,
                name: profileName,
                groupIds: groupsSelected,
            });
            if (status === 200) {
                this.modalSuccess('Perfil cadastrado com sucesso');
            } else {
                this.modalError(`Não foi Cadastrar o perfil ${profileName}!`);
            }
            this.show_loading = false;
        },
    },
};
</script>
    <style lang="scss" scoped>
    @import "@core/scss/vue/pages/new-client.scss";
    </style>
